






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { actionTypes, targetTypes } from "@/constants/actionLogs";

function itemDownloadButton(index) {
  return {
    attrs: {},
    content: {
      icon: "mdi-download",
    },
    on: {
      xClick({ self }) {
        const { item } = self.context();
        console.log(item);
        coreApiClient.download("actionLogs", `${item._id}/files/${item.fileItems[index].uuid}`);
      },
    },
    target: {
      tooltip: {
        content: {
          text: `Download File ${+index + 1}`,
        },
      },
    },
    ext: {
      condition(_, item) {
        return item && item.fileItems && item.fileItems[index];
      },
    },
  };
}

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Time (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Time (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              actionType: {
                type: "select",
                attrs: {
                  label: "Action",
                  items: makeSelectOptions(actionTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
              targetType: {
                type: "select",
                attrs: {
                  label: "Target",
                  items: makeSelectOptions(targetTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
              targetId: {
                attrs: {
                  label: "Target ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              remoteIp: {
                type: "text",
                attrs: {
                  label: "IP",
                },
                rules: {
                  equal_to: {},
                },
              },
              targetOrderIds: {
                attrs: {
                  label: "Order ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              "actionParams.barcodes": {
                attrs: {
                  label: "Barcode",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
              hidden: true,
            },
            user: {
              text: "User",
              sortable: true,
              options: {
                subProp: "user.email",
              },
            },
            actionType: {
              text: "Action",
              sortable: true,
              options: {
                label(value) {
                  return actionTypes[value || ""] || value;
                },
              },
            },
            targetType: {
              text: "Target",
              sortable: true,
              options: {
                label(value) {
                  return targetTypes[value || ""] || value;
                },
              },
            },
            content: {
              text: "Description",
              options: {
                preTransform(_, item) {
                  const target = {
                    ...(item.targetBefore || {}),
                    ...(item.targetAfter || {}),
                  };
                  return (
                    target.name ||
                    item.actionParams?.username ||
                    item.actionParams?.barcode ||
                    item.targetOrderIds?.join(", ")
                  );
                },
              },
            },
            remoteIp: {
              text: "IP",
              sortable: true,
            },
            createdTime: {
              text: "Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("actionLogs", `@/export`, findAllOptions);
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
          template: {
            itemActionButtons: {
              download0: itemDownloadButton(0),
              download1: itemDownloadButton(1),
              download2: itemDownloadButton(2),
              download3: itemDownloadButton(3),
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("actionLogs", "findAll", options);
              return [items, count];
            },
          },
          delete: {},
        },
      },
    };
  },
});
