export const actionTypes = {
    "Create": "Create",
    "Update": "Update",
    "Delete": "Delete",
    "Login": "Login",
    "Export": "Export Excel",
    "Import": "Import Excel",
    "CreateMany": "Create Many",
}

export const targetTypes = {
    "ActionLog": "Action Log",
    "Store": "Store",
    "Setting": "Setting",
    "User": "User",
    "Order": "Order",
}
